/* Ensure the whole screen is covered with the background */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #f7f7f7 30%, #d3d3d3 70%); /* Adjusted gradient to blend */
  font-family: 'Lato', 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Ensure items wrap if necessary */
  width: 80%; /* Adjusted to percentage for responsiveness */
  max-width: 1200px; /* Optional max width for larger screens */
  background: rgba(255, 255, 255, 0.9); /* Slightly opaque background for content */
  padding: 20px; /* Reduced padding for smaller screens */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center align horizontally */
}

.left-side, .right-side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.left-side {
  flex-direction: column;
  margin-right: 10px; /* Small space between left and right side */
}

.right-side {
  background: white; /* Changed to white background */
  padding: 0.5rem; /* Adding padding to ensure image doesn't touch edges */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.app-logo {
  height: 80px; /* Adjusted for responsiveness */
  margin-bottom: 20px;
  background: white; /* Ensure the logo background is white */
}

.main-heading {
  color: #2c3e50;
  font-size: 2em; /* Adjusted for responsiveness */
  margin-bottom: 1em;
  font-weight: 700; /* Bold */
  font-family: 'Roboto', 'Lato', sans-serif; /* Apply the new font */
}

.google-sign-in-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out;
  font-size: 16px;
  font-weight: bold;
}

.google-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.google-sign-in-btn:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.carousel .slide {
  background: white; /* Ensure carousel slide background matches container */
}

.carousel .control-dots .dot {
  background: #888; /* Custom color for the dots */
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the area without distortion */
  border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    align-items: center; /* Ensure centered alignment */
    height: auto;
    padding: 20px;
  }

  .left-side {
    margin-right: 0; /* Remove margin for smaller screens */
    margin-bottom: 10px; /* Add bottom margin for smaller screens */
    align-items: center; /* Center align items */
  }

  .right-side {
    padding: 0.5rem; /* Maintain padding for smaller screens */
    width: 100%; /* Ensure full width */
    align-items: center; /* Center align items */
  }

  .app-logo {
    height: 60px; /* Adjusted for smaller screens */
  }

  .main-heading {
    font-size: 1.5em; /* Adjusted for smaller screens */
  }

  .google-sign-in-btn {
    font-size: 14px; /* Adjusted for smaller screens */
  }

  .carousel-image {
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensure the image fits within the container */
  }
}
