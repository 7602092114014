svg#freepik_stories-online:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-online.animated #freepik--Floor--inject-348 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}

svg#freepik_stories-online.animated #freepik--Icons--inject-348 {
  animation: 1s 1 forwards ease-in lightSpeedLeft, 3s Infinite linear spin;
  animation-delay: 0s, 2s;
}

svg#freepik_stories-online.animated #freepik--Icons--inject-348 .icon-spin {
  animation: 3s infinite linear spin;
}
svg#freepik_stories-online.animated #freepik--Character--inject-348 {
  animation: 1s 1 forwards linear lightSpeedRight, 1.5s Infinite linear floating;
  animation-delay: 0s, 1s;
}
svg#freepik_stories-online.animated #freepik--Icons--inject-348.icon-spin {
  animation: 3s infinite linear spin;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
@keyframes lightSpeedLeft {
  from {
    transform: translate3d(-50%, 0, 0) skewX(20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(-10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animated .animable.icon-spin {
  animation: spin 2s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

svg#freepik_stories-success:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-success.animated #freepik--Floor--inject-348 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-success.animated #freepik--Icons--inject-348 {
  animation: 1s forwards ease-in lightSpeedLeft, 3s linear infinite spin;
  animation-delay: 0s, 1s;
}

svg#freepik_stories-success.animated #freepik--Icons--inject-348 .icon-spin {
  animation: 3s infinite linear spin;
}
svg#freepik_stories-success.animated #freepik--Character--inject-348 {
  animation: 1s 1 forwards linear lightSpeedRight, 1.5s infinite linear floating;
  animation-delay: 0s, 1s;
}

svg#freepik_stories-success.animated #freepik--background-simple--inject-348 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}

@keyframes lightSpeedRight {
  from {
    transform: translate3d(50%, 0, 0) skewX(-20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.svg-container {
  position: relative;
  left: 25%;
  transform: translateY(20%);
  opacity: 1;
  z-index: 0;
}
