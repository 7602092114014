/* success.css */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Lato", "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw; /* Ensure full width */
  height: 100vh; /* Ensure full height */
  background: linear-gradient(
    to right,
    #e0e0e0,
    #cccccc
  ); /* Adjusted gradient to blend */
  position: relative;
  overflow: hidden;
}

.success-content {
  z-index: 1;
  text-align: center;
  max-width: 600px;
  margin: 0 auto; /* Limit text width for better readability */ /* Center the text content */
  padding: 20px;
}

.svg-container {
  bottom: 5%;
  opacity: 1; /* Blend the image with the background */
  z-index: 0;
  width: 35%;
  max-height: 30%;
}
@media (max-width: 768px) {
  .success-content {
    max-width: 90%; /* Increase text width for smaller screens */
    padding: 10px;
  }

  .svg-container {
    width: 50%; /* Adjust SVG width for smaller screens */
    max-height: 40%;
  }
}

@media (max-width: 480px) {
  .success-content {
    max-width: 100%; /* Full width for very small screens */
    padding: 5px;
  }

  .svg-container {
    width: 70%; /* Further adjust SVG width for very small screens */
    max-height: 50%;
  }
}
