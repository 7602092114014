/* Footer styles */
.footer {
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
  font-family: 'Lato', 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Ensure it uses the same font */
  opacity: 0.10; /* Reduce opacity */
  cursor: pointer; /* Change cursor to pointer to indicate clickability */
  margin-top: 2.5%;
}

.footer:hover {
  opacity: 1; /* Fully opaque on hover */
  color: #007BFF; /* Change color on hover */
}

